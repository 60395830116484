@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(./fonts/Nunito/Nunito-VariableFont_wght.ttf) format('woff');
}

body {
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html, #root, .app {
  height: 100%;
}

.active, .active:hover {
  color: #20A39E !important;
  font-weight: bold;
}

.active::after {
  content: '\2756';
  margin-left: 0.25rem;
}

a:hover .shb-overlay-bg {
  background-color: #6F58C9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 768px) {
  .photo-detail {
    width: 100% !important;
  }
}
